import { useEffect } from 'react'
import { navigate } from 'gatsby'
import { parse } from 'query-string'
import useLocalStorage, {
  removeFromLocalStorage
} from '../utils/useLocalStorage'

import api from '../api'
import {
  areResponsesExpired,
  trackPlanViewEvent
} from '../components/Program/PlanUtils'
import { logWarn } from '../utils/logger'
import { useUserContext } from '../contexts/User/userContext'
import { useUserAuthToken } from 'goodpath-common'

export default function PlanRedirect({ location }) {
  // Get the plan ID from local storage if it exists (this will only be used if a planID is not passed as a URL param)
  const [storedPlanId] = useLocalStorage('planId')

  const { token } = useUserAuthToken(useUserContext)
  useEffect(() => {
    async function init() {
      let queryProgramId = (parse(location.search) || {}).planId

      const planVersion = `simplePlan-1`

      // Used stored plan ID if available
      if (queryProgramId === undefined && storedPlanId) {
        console.info(
          `plan: No planId in URL. Using planId ${storedPlanId} from local storage`
        )
        queryProgramId = storedPlanId
      }

      console.info(`plan: queryPlanId=${queryProgramId}`)

      // * Checking if planId exist as a query parameter
      try {
        if (queryProgramId) {
          // * Fetching plan by planId from the server
          const {
            conditionSlug,
            createdAt,
            user: { userId, email, firstName } = {}
          } = await api.assessment.getIntakeDetails(queryProgramId)

          console.info(`plan: conditionSlug=${conditionSlug}`)

          // If the responses are expired then navigate to the condition landing page
          if (areResponsesExpired({ responsesCreatedAt: createdAt })) {
            console.log(
              'Expired plan accessed on the plan lander page. Navigating to condition lander.',
              { conditionSlug, planId: queryProgramId }
            )
            // We still want to track a plan view for non-views with an attribute set indicating the result...
            trackPlanViewEvent({
              userId,
              email,
              firstName,
              planId: queryProgramId,
              origin: location.origin,
              conditionSlug,
              planVersion
            })
            return navigate(`/old-quiz/${conditionSlug}`)
          } else if (conditionSlug) {
            if (typeof window !== `undefined`)
              window.history.pushState(
                { prevUrl: window.location.href },
                null,
                '/'
              )
            console.info(`plan: Navigating to plan...`)
            return navigate(`/plan/${conditionSlug}?planId=${queryProgramId}`)
          } else {
            // Clear the planId so the user can re-take the quiz.
            removeFromLocalStorage('planId')
            logWarn({
              msg: `plan: no conditionSlug, planId cleared from local storage and navigate to homepage`
            })

            // We still want to track a plan view for non-views with an attribute set indicating the result...
            trackPlanViewEvent({
              userId,
              email,
              firstName,
              planId: queryProgramId,
              origin: location.origin,
              conditionSlug,
              planVersion
            })
            return navigate('/')
          }
        } else {
          // Clear the planId so the user can re-take the quiz.
          removeFromLocalStorage('planId')
          logWarn({
            msg: `plan: no value for queryPlanId, planId cleared from local storage and navigate to homepage`
          })
          navigate('/')
        }
      } catch (e) {
        return navigate('/')
      }
    }

    token && init()
  }, [location.origin, location.search, storedPlanId, token])

  return null
}
