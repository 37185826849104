import { logErr, logWarn } from '../../utils/logger'
import {
  setUserAttributes,
  trackUserAction
} from '../../utils/analytics-tracking'
import { isEmpty } from 'lodash'
import { dateDiff, getCurrentQuiz } from 'goodpath-common'

// No flatmap in node.js??? Oh well, let's add it...
const flatMap = (f, xs) => xs.reduce((acc, x) => acc.concat(f(x)), [])

// eslint-disable-next-line no-extend-native
Array.prototype.flatMap = function (f) {
  return flatMap(f, this)
}

export const PLAN_VIEW_ACTION_OLD_PLAN = 'PLAN_VIEW_ACTION_OLD_PLAN'
export const PLAN_VIEW_ACTION_NO_PLANID = 'PLAN_VIEW_ACTION_NO_PLANID'
export const PLAN_VIEW_ACTION_NO_SOLUTIONS = 'PLAN_VIEW_ACTION_NO_SOLUTIONS'

export function trackPlanViewEvent({
  userId,
  email,
  firstName,
  conditionSlug
}) {
  try {
    if (!email) {
      console.log('No email provided')
      return
    }
    setUserAttributes({ userId, email, firstName })

    trackUserAction('Plan View', {
      includeUnique: true,
      condition: conditionSlug.condition
    })
  } catch (err) {
    logErr({
      err,
      msg: 'Error triggering the plan view event.',
      extra: { userId, email, conditionSlug }
    })
  }
}

export function selectDefaultProductForSolution(solutions) {
  return solutions.map((s) => {
    const displayedProduct =
      s.products && s.products.length ? s.products[0] : null
    return { ...s, displayedProduct }
  })
}

// TODO: This needs a name that is less generic. I think `groupSolutionBy` would be fine.
//  Not changing in this PR to reduce file modifications for now.
export const groupBy = (array, key) => {
  // Accepts the array and key and group them
  return array.reduce((result, solution) => {
    const groupId = solution[key] && solution[key][0] && solution[key][0].id

    if (!groupId) {
      logWarn({
        msg: `Solution has no group: ${solution.name}`,
        extra: { solutionId: solution?.solutionId }
      })
    }

    // ! Not return here will generate a 'null' key on SolutionGroup object
    if (result[groupId]) {
      result[groupId].push(solution)
    } else {
      result[groupId] = [solution]
    }
    return result
  }, {})
}

/**
 * Returns true if every question from the current quiz has a response in responses
 * else returns false if some questions are unanswered.
 */
export const areResponsesSufficientForQuiz = ({
  planId,
  responses,
  conditionSlug,
  quizzes,
  // If true, we'll stop once we find any condition that makes the plan insufficient instead of checking for all conditions.
  shortCircuitCheck = true
}) => {
  const currentQuiz = getCurrentQuiz(quizzes, conditionSlug)

  // If we don't have quiz then the page doesn't match the plan condition. So, it's not going to have sufficient responses.
  // So, we'll short circuit out (also, we can't actually do the below code without a currentQuiz :-P).
  if (!currentQuiz) {
    logErr({
      msg: 'No current quiz when checking for backwards compatibility.',
      extra: { planId, responses, conditionSlug }
    })
    return { isSufficient: false, reason: 'No quiz data passed' }
  }

  const flow = currentQuiz.flow
  const questions = flow.filter((e) => e.internal.type === 'ContentfulQuestion')
  const missingQuestions = questions.reduce((missingQuestions, question) => {
    // take care of exlusions
    let exclude = false
    // eslint-disable-next-line no-unused-expressions
    question.exclusionResponses?.forEach((item) => {
      if (responses.findIndex((r) => r.responseId === item.contentfulid) > -1) {
        exclude = true
      }
    })

    // Take care of exclusions due to NOT being included (if there are any inclusionResponses)
    if (question.inclusionResponses?.length > 0) {
      let excludeDueToNoInclusion = true
      for (const item of question.inclusionResponses) {
        if (
          responses.findIndex((r) => r.responseId === item.contentfulid) > -1
        ) {
          excludeDueToNoInclusion = false
          break
        }
      }

      if (excludeDueToNoInclusion) {
        exclude = true
      }
    }

    if (!exclude && (!shortCircuitCheck || isEmpty(missingQuestions))) {
      const hasResponse =
        responses.findIndex((r) => r.questionId === question.contentfulid) > -1

      if (!hasResponse) {
        missingQuestions.push(question.contentfulid)
      }

      return missingQuestions
    } else {
      return missingQuestions
    }
  }, [])

  if (!isEmpty(missingQuestions)) {
    console.log(
      'Quiz responses are insufficient for plan due to no valid response for some questions.',
      {
        planId,
        responses,
        conditionSlug,
        currentQuiz,
        checkWasShortCircuited: shortCircuitCheck,
        missingQuestions
      }
    )
  }

  return {
    isSufficient: isEmpty(missingQuestions),
    reason: 'Quiz has questions without responses',
    missingQuestions
  }
}

export const ACTION_NO_ACTION = 'ACTION_NO_ACTION'
export const ACTION_REDIRECT_OLD_PLAN = 'ACTION_REDIRECT_OLD_PLAN'
export const ACTION_REDIRECT_PLAN_CONDITION = 'ACTION_REDIRECT_PLAN_CONDITION'
export const ACTION_REDIRECT_PLAN_ERROR = 'ACTION_REDIRECT_PLAN_ERROR'
export const ACTION_SHOW_STORED_PLAN = 'ACTION_SHOW_STORED_PLAN'

// Returns true if the quiz responses are considered expired (currently, we consider them expired after 30 days) else
//  return false.
export const areResponsesExpired = ({ responsesCreatedAt }) => {
  const maxAgeDays = 30
  const now = Date.now()
  return dateDiff(now, responsesCreatedAt, 'days', false) > maxAgeDays
}
